<template>
  <defaultSec class="chat-record-detail" :title="'< 返回'" :returnState="true" :isDisableScroll="true" :isFlexSection="true">
      <section class="chat-record-list">
        <div class="route-left">
          <div class="info-content">
            <el-select size="mini" v-model="userId" @change="changeCustomer">
              <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
              <el-option v-for="item in userList" :value="item.id" :label="item.name"></el-option>
            </el-select>
            <span class="info-count">共{{cusTotal}}个聊天</span>
          </div>
          <div class="left-title">
            <el-select size="mini" v-model="searchValue" style="width: 52px">
              <el-option value="0" label="昵称"></el-option>
              <el-option value="1" label="日期"></el-option>
            </el-select>
            <el-date-picker
                style="flex:1"
                size="mini"
                v-if="searchValue==1"
                v-model="chatParam.date"
                value-format="yyyy-MM-dd"
                type="daterange"
                align="left"
                unlink-panels
                range-separator="~"
                start-placeholder="start"
                end-placeholder="end"
                :picker-options="pickerOptions">
            </el-date-picker>
            <el-input style="flex:1" v-else size="mini" v-model="chatParam.name" placeholder="请输入"></el-input>
            <el-button type="primary" size="mini" @click="initList()" icon="el-icon-search"></el-button>
          </div>
          <el-scrollbar class="route-left-wrap" v-loading="leftLoading">
            <div @click="selectUser(item,index)" class="card-item" :class="selectChatIndex==index?'card-item-on':''" v-for="(item,index) in chatList">
              <el-avatar shape="square" :size="50" :src="item.avatar"></el-avatar>
              <div style="flex: 1;margin-left: 5px">
                <h3>{{item.defineName||item.name}}</h3>
                <p>微信昵称：{{item.name}}</p>
                <h5>{{item.maxMsgTime}}</h5>
              </div>
            </div>
            <el-empty v-if="chatList.length<=0" description="暂无记录"></el-empty>
          </el-scrollbar>
        </div>

        <div class="route-right">
          <el-row type="flex" class="right-title">
              <el-col style="flex: 1">
                <h3 style="display:flex;align-items:center;font-size: 18px">
                  <el-avatar shape="square" :size="40" :src="selUser.avatar"></el-avatar>
                  <span style="display:block;margin-left: 10px">{{selUser.defineName||selUser.name}}</span>
                </h3>
                <h5 style="margin-top:10px;font-size: 10px;color: #a5a5a5">最后聊天：{{selUser.maxMsgTime}}</h5>
              </el-col>
              <el-col style="display: flex;width: 435px">
                <el-date-picker
                    style="flex:1"
                    size="mini"
                    v-model="chatDetailParam.date"
                    value-format="yyyy-MM-dd"
                    @change="dateDetailChange"
                    type="daterange"
                    align="left"
                    unlink-panels
                    range-separator="~"
                    start-placeholder="start"
                    end-placeholder="end"
                    :picker-options="pickerOptions">
                </el-date-picker>
                <el-input style="flex:1;margin-left: 10px" size="mini" v-model="searchChatTextValue" placeholder="请输入关键字搜索">
                  <el-button size="mini" slot="append" icon="el-icon-search" @click="searchDetailSubmit"></el-button>
                </el-input>
              </el-col>
            </el-row>
          <div style="margin-bottom: 10px">
            <el-radio-group size="mini" v-model="chatType" @change="chatTypeToggle">
              <el-radio-button label="">聊天记录</el-radio-button>
              <el-radio-button label="image">图片</el-radio-button>
              <el-radio-button label="video">视频</el-radio-button>
              <el-radio-button label="voice">语音通话</el-radio-button>
              <el-radio-button label="search">关键字搜索结果</el-radio-button>
            </el-radio-group>
          </div>
          <el-scrollbar class="chat-detail-wrap-content chat-detail-content" v-loading="rightLoading">
            <template v-if="chatType===''">
              <div v-for="item1 in chatDetailList" class="chat-item" :class="item1.speakType==2?'':'chat-item-reverse'">
                <div class="chat-img"><el-avatar size="small" :src="item1.speakType==2?item1.avatar:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg'"></el-avatar></div>
                <p class="chat-msg-time">{{item1.msgTime}}</p>
                <div class="chat-miniprogram" v-if="item1.msgType=='weapp'">
                  <p style="display: flex;align-items: center;font-size: 10px"><el-avatar style="margin-right: 6px" size="small" src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg"></el-avatar> 乐纯</p>
                  <p>{{item1.weappTitle}}</p>
                  <img style="width: 200px;height: 150px" src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg">
                  <p style="border-top: 1px solid #dddddd;padding-top: 6px;font-size: 10px">小程序</p>
                </div>
                <div class="chat-content" v-else-if="item1.msgType=='text'" v-html="item1.textContent"></div>
                <div class="chat-content chat-image" v-else-if="item1.msgType=='image'||item1.msgType=='emotion'">
                  <el-image :src="item1.ossUrl" :preview-src-list="[item1.ossUrl]"></el-image>
                </div>
                <div class="chat-content chat-voice" v-else-if="item1.msgType=='voice'" @click="playVoice(item1)">{{item1.amrTime}}"</div>
                <div class="chat-content" v-else>[ {{item1.msgType}} ]</div>
              </div>
            </template>
            <template v-else>
              <el-card style="margin-bottom: 10px;font-size: 12px" v-for="item in chatDetailList">
                <div style="display: flex;align-items: center;justify-content: space-between">
                  <div style="display: flex;flex: 1">
                    <el-avatar style="margin-right: 10px;" shape="square" size="small" :src="item.speakType==2?item.avatar:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg'"></el-avatar>
                    <div style="flex: 1">
                      <p style="margin-bottom: 10px;font-weight: 600">{{item.speakType==2?item.name:'乐纯'}}</p>
                      <el-image v-if="chatType=='image'||chatType=='video'" fit="cover" style="width: 50px;height: auto;display: block" :src="item.ossUrl" :preview-src-list="[item.ossUrl]"></el-image>
                      <p v-else-if="chatType=='voice'" class="chat-voice" @click="playVoice(item)">{{item.amrTime}}"</p>
                      <p v-else-if="chatType=='search'" v-html="highLightSearchText(item.textContent)">{{item.textContent}}"</p>
                    </div>
                  </div>
                  <div>
                    <p>{{item.msgTime}}</p>
                    <el-link v-if="chatType=='search'" type="primary" style="margin-top:10px;width: 100px" @click="openUpDownRecord(item)">查找上下文</el-link>
                  </div>
                </div>
              </el-card>
            </template>
            <el-empty v-if="chatDetailList.length<=0" description="暂无记录"></el-empty>
          </el-scrollbar>
        </div>
      </section>
    <el-drawer
        :modal-append-to-body="false"
        title="搜索记录上下文"
        size="60%"
        :visible.sync="keyResultDrawer">
      <div class="drawer-content">
        <div v-for="item1 in resultUpDownRecordData" class="chat-item" :class="item1.speakType==2?'':'chat-item-reverse'">
          <div class="chat-img"><el-avatar size="small" :src="item1.speakType==2?item1.avatar:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg'"></el-avatar></div>
          <p class="chat-msg-time">{{item1.msgTime}}</p>
          <div class="chat-miniprogram" v-if="item1.msgType=='weapp'">
            <p style="display: flex;align-items: center;font-size: 10px"><el-avatar style="margin-right: 6px" size="small" src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg"></el-avatar> 乐纯</p>
            <p>{{item1.weappTitle}}</p>
            <img style="width: 200px;height: 150px" src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg">
            <p style="border-top: 1px solid #dddddd;padding-top: 6px;font-size: 10px">小程序</p>
          </div>
          <div class="chat-content" v-else-if="item1.msgType=='text'" v-html="item1.textContent"></div>
          <div class="chat-content chat-image" v-else-if="item1.msgType=='image'||item1.msgType=='emotion'">
            <el-image :src="item1.ossUrl" :preview-src-list="[item1.ossUrl]"></el-image>
          </div>
          <div class="chat-content chat-voice" v-else-if="item1.msgType=='voice'" @click="playVoice(item1)">{{item1.amrTime}}"</div>
          <div class="chat-content" v-else>[ {{item1.msgType}} ]</div>
        </div>

      </div>
    </el-drawer>
  </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
import BenzAMRRecoder from 'benz-amr-recorder';
export default {
  name: "chatrecord",
  data(){
    return{
      leftLoading:false,
      rightLoading:false,
      userId:this.$route.params.id||'',
      cusTotal:0,
      cusDetailTotal:0,
      searchValue:'0',
      userList:[],
      chatList:[],
      selUser:{},
      chatDetailList:[],
      resultUpDownRecordData:[],
      selectChatIndex:0,
      chatParam:{
        currentPage:1,
        pageSize:50,
        qyWeixinUserid:this.$route.params.id,
        sdate:'',
        edate:'',
        name:'',
        date:['','']
      },
      chatDetailParam:{
        currentPage:1,
        pageSize:50,
        qyWeixinUserid:this.$route.params.id,
        externalUserid:'',
        sdate:'',
        edate:'',
        content:'',
        curId:'',
        date:['','']
      },
      searchChatTextValue:'',
      searchResultState:false,
      searchTotal:0,
      searchResultParam:{
        currentPage:1,
        pageSize:10,
      },
      searchResultList:[],
      chatType:'',
      recordDrawerShow:false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      keyResultDrawer:false
    }
  },
  watch:{
    '$route'(to,from){
      //监听路由变化
      if(to.params.id!==from.params.id){
        this.chatParam.qyWeixinUserid = to.params.id
        this.chatDetailParam.qyWeixinUserid = to.params.id
        this.initList()
      }
    }
  },
  components: {defaultSec,tablePagination},
  mounted() {
    this.initUserList()
    this.initList()
    this.leftScroll()
    this.rightScroll()
  },
  methods:{
    //企微客服列表
    initUserList(){
      this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
        this.userList = res
      })
    },
    //切换客服
    changeCustomer(item){
      this.$router.push({path:'/chatrecorddetail/:id',name:'chatRecordDetail',params:{id:item},query:{name:item.name}})
    },
    leftScroll(){
      //监听滚动
      let dom = document.querySelector('.route-left-wrap .el-scrollbar__wrap')
      dom.scrollTop = 0
      dom.addEventListener('scroll',()=>{
          let scrollTop = dom.scrollTop
          let clientHeight = dom.clientHeight
          let scrollHeight = dom.scrollHeight
          if(scrollTop+clientHeight>=scrollHeight){
            console.log(scrollTop)
              if(this.chatList.length<this.cusTotal){
                  this.leftLoading = true
                  setTimeout(()=>{
                      this.chatParam.currentPage++
                      this.initList('scroll')
                  },1000)

              }
          }
      })
      //加载数据销毁滚动
      if(this.leftLoading){
        console.log('leftd')
          document.querySelector('.route-left-wrap .el-scrollbar__wrap').removeEventListener('scroll',()=>{})
      }
    },
    //设置聊天记录滚动到底部
    setChatScrollBottom(){
      this.$nextTick(()=>{
        let dom = document.querySelector('.chat-detail-content .el-scrollbar__wrap')
        console.log(dom)
        dom.scrollTop = 100000000000
      })
    },
    rightScroll(){
      //监听滚动
      let dom = document.querySelector('.chat-detail-content .el-scrollbar__wrap')
      dom.addEventListener('scroll',()=>{
        let scrollTop = dom.scrollTop
        let clientHeight = dom.clientHeight
        let scrollHeight = dom.scrollHeight
        console.log(scrollTop,clientHeight,scrollHeight)
        if(scrollTop<=0){
          console.log(scrollTop,this.chatDetailList.length,this.cusDetailTotal)
          if(this.chatDetailList.length<this.cusDetailTotal){
            this.rightLoading = true
            setTimeout(()=>{
              this.chatDetailParam.currentPage++
              this.chatDetailFunc('scroll').then(()=>{
                this.$nextTick(()=>{
                  dom.scrollTop = scrollHeight-100
                })
              })
            },1000)

          }
        }
      })
      //加载数据销毁滚动
      if(this.rightLoading){
        document.querySelector('.chat-detail-content .el-scrollbar__wrap').removeEventListener('scroll',()=>{})
      }
    },
    //初始化用户记录数据
    initList(from){
      if(!from){
        this.chatParam.currentPage = 1
        this.chatDetailParam.currentPage = 1
        this.chatDetailList = []
        this.chatDetailParam.msgType = ''
        this.selUser = {}
        document.querySelector('.route-left-wrap .el-scrollbar__wrap').scrollTop = 0
      }

      this.leftLoading = true
      let {date,...params} = this.chatParam
      params.sdate = date[0]
      params.edate = date[1]
      this.$apiGet('lechun-bi/generateScene/getQwChatCustomerList', {...params}).then(res => {
        if(from=='scroll'){
          this.chatList = this.chatList.concat(res.list)
        }else{
          this.chatList = res.list
        }
        this.leftLoading = false
        this.cusTotal = res.total

        if(this.chatList.length>0&&from!=='scroll'){
          this.selectUser(this.chatList[0],0)
        }
      })
    },
    //用户切换
    selectUser(item,index){
      this.selUser = {...item}
      this.chatDetailParam.externalUserid = item.externalUserid
      this.selectChatIndex = index
      this.chatDetailParam.msgType = ''
      this.chatType = ''
      this.searchChatTextValue = ''
      this.chatDetailFunc().then(()=>{
        this.$nextTick(()=>{
          this.setChatScrollBottom()
        })
      })
    },
    chatTypeToggle(type){
      this.chatDetailParam.currentPage = 1
      this.cusDetailTotal = 0
      this.chatDetailList = []
      if(type=='search'&&this.searchChatTextValue!==''||type!='search'){
        this.chatDetailFunc().then(()=>{
          if(type===''){
            this.$nextTick(()=>{
              this.setChatScrollBottom()
            })
          }
        })
      }

    },
    chatDetailFunc(from){
      return new Promise((resolve,reject)=>{
        if(!from){
          this.chatDetailParam.currentPage = 1
          document.querySelector('.chat-detail-content .el-scrollbar__wrap').scrollTop = 0

        }
        this.rightLoading = true
        let {date,...params} = this.chatDetailParam
        params.sdate = date[0]
        params.edate = date[1]
        params.msgType = this.chatType=='search'?'':this.chatType
        params.content = this.chatType=='search'?this.searchChatTextValue:''
        this.$apiGet('lechun-bi/generateScene/getQwChatList',{...params}).then(res => {
          //处理微信语音amr
          let list = res.list.map(item=>{
            if(item.msgType=='voice') {
              var amr = new BenzAMRRecoder();
              amr.initWithUrl(item.ossUrl)
              item.amrTime = ''
              item.amr = amr
            }
            return item
          })
          if(from=='scroll'){
            this.chatDetailList = [...list,...this.chatDetailList]
          }else{
            this.chatDetailList = list
          }
          this.cusDetailTotal = res.total
          this.rightLoading = false
          resolve()
        })
      })

    },
    dateDetailChange(){
      this.chatDetailFunc().then(()=>{
        if(this.chatType===''){
          this.$nextTick(()=>{
            this.setChatScrollBottom()
          })
        }
      })
    },
    searchDetailSubmit(){
      this.chatType = 'search'
      this.chatDetailList = []
      if(this.searchChatTextValue!==''){
        this.chatDetailFunc()
      }

    },
    playVoice(item){
      let amr = item.amr
      this.$set(item,'amrTime',Math.ceil(amr.getDuration()))
      if(amr.isPlaying()){
        amr.pause()
      }else{
        amr.play()
      }
    },
    highLightSearchText(text){
      if(!text){
        return false
      }
      let value = this.searchChatTextValue
      if(value&&this.chatType=='search'){
        let reg = new RegExp('</?span.*?>','gi')
        console.log(value,reg,text)
        text = text.replace(reg,'')
        let reg1 = new RegExp(value,'gi')
        let arr = text.match(reg1)
        let i = -1
        text = text.replace(reg1,()=>{
          i++
          return '<span style="color:#e50011;background: rgba(228,255,4,.4)">'+arr[i]+'</span>'
        })
      }
      return text
    },
    //打开上下文
    openUpDownRecord(item){
      this.keyResultDrawer = true
      let {date,...params} = this.chatDetailParam
      params.sdate = date[0]
      params.edate = date[1]
      params.curId = item.id

      this.$apiGet('lechun-bi/generateScene/getQwChatList',{...params}).then(res => {
        //处理微信语音amr
        this.resultUpDownRecordData = res.list
      })
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.chat-record-detail{
  p,h3,h4,h5{
    margin: 0;
    padding: 0;
  }
  .chat-record-list{

    display: flex;
    height: 100%;
  }

  .popper-content{
    box-shadow: 0 0 10px 0 rgba(0,0,0,.4)
  }
  .route-left-wrap{
    height: calc(100% - 74px);
  }
  .info-content{
    position: relative;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 100px;
    box-sizing: border-box;
    .info-count{
      position: absolute;
      right: 0;
      top: 0;
      font-weight: normal;
      color: #8c8c8c;
    }
  }
  .left-title{
    display: flex;
    margin-bottom: 15px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.2);
    .el-input--mini .el-input__inner{
      padding-left: 2px;
      padding-right: 2px;
    }
    .el-range-editor.el-input__inner{
      padding: 3px;
    }
    .el-button--mini{
      padding: 7px 10px;
    }
  }
  .route-left{
    width: 280px;
    border-radius: 5px 0 0 5px;
    padding: 10px;
    background: #f5f5f5;
    font-size: 12px;
    box-shadow: 1px 0 6px 0 rgba(0,0,0,.26);
    position: relative;
    z-index: 9;
  }
  .card-item{
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #ffffff;
    border-bottom: 1px solid #e8e8e8;
    box-sizing: border-box;
    padding: 10px;
    p{
      font-size: 9px;color: #7c7c7c
    }
    h5{
      font-weight: normal;
      margin-top:3px;text-align: right;font-size: 10px;color: #cccccc
    }
  }
  .card-item-on{
    background: #209e91;
    color: #ffffff;
    p,h5{
      color: #ffffff;
    }

  }
  .route-right{
    flex: 1;
    height: calc(100% - 20px);
    background: #ffffff;
    border-radius: 0 5px 5px 0;
    padding: 10px 10px 10px 15px;
  }
  .right-title{
    align-items: center;
    border-bottom: 1px solid #DCDFE6;
    padding-bottom: 24px;
    margin-bottom: 15px;
  }
  .chat-detail-wrap-content{
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.2);
    height: calc(100% - 150px);
    padding: 15px 20px;
    box-sizing: border-box;
  }
  .chat-item{
    position: relative;
    padding-top: 20px;
    display: flex;
    margin-bottom: 20px;
    font-size: 12px;
  }

  .chat-item-reverse{
    flex-direction: row-reverse;
  }
  .chat-img{
    width: 28px;
    display: block;
    margin-right: 10px;
  }
  .chat-msg-time{
    position: absolute;
    top: 0;
    left: 38px;
    font-size: 10px;
    color: #999999;
  }
  .chat-item-reverse .chat-msg-time{
    left: auto;
    right: 38px;
  }
  .chat-img img{
    width: 28px!important;
  }
  .chat-item-reverse .chat-img{
    margin-left: 10px;
    margin-right: 0;
  }

  .chat-content{
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    white-space: pre-wrap;
    max-width: 80%;

  }
  .chat-image{
    img{
      width: 100px;
      display: block;
    }
  }

  .chat-item-reverse .chat-content{
    background: #a6e860;
  }
  .chat-item-reverse .chat-image{
    background: transparent;
  }
  .chat-miniprogram{
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px;
    width: 200px;
  }
  .chat-voice{
    border-radius: 5px;
    padding: 10px;
    width:100px;
    background: #f5f5f5 url("http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/icon-voice.png") center left no-repeat;
    background-size: 16px auto;
    padding-left: 26px;
    background-position-x: 5px;
  }
  .voice-text{
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    white-space: pre-wrap;
    max-width: 80%;
  }
  .drawer-content{
    //box-shadow: 0 2px 12px 0 rgba(0,0,0,.2);
    padding: 15px 20px;
    box-sizing: border-box;
  }
}

</style>